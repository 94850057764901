export const environment = {
  production: false,
  hmr: false,
  // apiUrl: 'http://localhost:5300',
  apiUrl: 'https://api.logswarm.com',
  // forumUrl: 'http://localhost:5000',
  forumUrl: 'https://api.logswarm.com',
  config: {
    apiKey: 'AIzaSyDWcsx3Sf63wTIeM0-4Z2g784KHhprRx2E',
    authDomain: 'logswarm.firebaseapp.com',
    // databaseURL: 'https://ngx-auth-firebaseui.firebaseio.com',
    projectId: 'logswarm.firebaseapp.com',
    storageBucket: 'logswarm.appspot.com',
    messagingSenderId: "327742603755",
    appId: "1:327742603755:web:79118de92e6c4db81799ae",
    measurementId: "G-6MYW6RNX4Q"
  }
};
