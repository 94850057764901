import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog} from "@angular/material/dialog";
import {LoginDialogComponent} from "../login-dialog/login-dialog.component";
import {AngularFireAuth} from "@angular/fire/compat/auth";


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  menuHidden = true;

  user;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private firebase: AngularFireAuth,
  ) {}

  ngOnInit() {
    this.firebase.onAuthStateChanged((user) => {
      if (user && user.multiFactor){
        this.user = user.multiFactor as any;;
      } else {
        this.user = null;
      }
    });
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  login() {
    this.dialog.open(LoginDialogComponent, {
      data: {
        type: 'login',
      }
    });
  }

  signup() {
    this.dialog.open(LoginDialogComponent, {
      data: {
        type: 'signup',
      },
    });
  }

  logout() {
    // this.authenticationService.logout().subscribe(() => this.router.navigate(['/login'], { replaceUrl: true }));
  }

  get username(): string | null {
    return 'test user';
    // const credentials = this.credentialsService.credentials;
    // return credentials ? credentials.username : null;
  }
}
