import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import {AngularFireAuth} from "@angular/fire/compat/auth";

@Injectable()
export class FirebaseTokenInterceptor implements HttpInterceptor {
  constructor(private afAuth: AngularFireAuth) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return this.afAuth.idToken.pipe(
      switchMap(idToken => {
        // Clone the request and add the Firebase token to the header
        const authReq = request.clone({
          headers: request.headers.set('Authorization', `Bearer ${idToken}`)
        });

        // Send the cloned request to the next handler
        return next.handle(authReq);
      })
    );
  }
}
