import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {InfoService} from "../shared/info.service";


@Component({
  selector: 'app-log-create',
  templateUrl: './log-create.component.html',
  styleUrls: ['./log-create.component.scss'],
})
export class LogCreateComponent implements OnInit {

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private infoService: InfoService,
    private snackBar: MatSnackBar,
  ) {
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.form = this.fb.group({
      system_id: ['', [
        Validators.minLength(3),
        Validators.maxLength(512),
        Validators.pattern(/^[a-z0-9\-]+$/),
      ]],
      body: ['', [
        Validators.maxLength(1024),
      ]],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const value = this.form.value as any;
      const system = {
        system_id: value.system_id,
        body: this.form.value.body
      };

      this.infoService.create(system).subscribe((response) => {
        this.snackBar.open('Created Log', undefined, {duration: 3000});
      }, (error) => {
        console.error(error);
        this.snackBar.open(error.message, 'Error', {duration: 3000});
      });

      this.form.reset();
    }
  }
}
