<mat-card>
  <mat-card-content>
    <ngx-auth-firebaseui
      (onSuccess)="log($event)"
      (onError)="log($event)"
      (selectedTabChange)="onTabChange($event)"
      [guestEnabled]="false"
      [tabIndex]="data?.type === 'signup' ? 1 : 0"
      appearance="outline"
      signInTabText="Login"
      signInCardTitleText="&nbsp;"
      registerTabText = "Sign up"
      registerCardTitleText = "&nbsp;"
      registerButtonText = "Sign up"
      resetPasswordTabText = "Reset password"
      resetPasswordInputText = "Reset password"
      [providers]="[AuthProvider.Github]"
    >
    </ngx-auth-firebaseui>
  </mat-card-content>
</mat-card>


<!--<mat-card>-->
<!--  <mat-card-content>-->
<!--    <ngx-auth-firebaseui-user (onSignOut)="onSignOut()"-->
<!--                              (onAccountDeleted)="onAccountDeleted()">-->
<!--    </ngx-auth-firebaseui-user>-->
<!--  </mat-card-content>-->
<!--</mat-card>-->
