<div class="toolbar" role="banner">
  <a href="https://logswarm.com">
    <img
      src="assets/images/logo.png"
      class="logo"
    />
  </a>
  <a href="https://logswarm.com">
    <img height="20" src="assets/images/name.png" class="name" alt="Log Swarm" />
  </a>
  <div class="search-container">
    <input type="text" class="search-system" placeholder="System" />
    <textarea class="search-logs" placeholder="Search logs" cdkTextareaAutosize ></textarea>
  </div>
  <div class="profile-container">
    <ng-container *ngIf="user===null">
      <button color="secondary"
              class="login"
              mat-button
              (click)="login()"
              matDialogClose>Login
      </button>
      <button color="primary"
              class="signup"
              mat-raised-button
              (click)="signup()"
              matDialogClose>Sign Up
      </button>
    </ng-container>
    <ng-container *ngIf="user">
      <ngx-auth-firebaseui-avatar
        [layout]="'simple'"
        [canViewAccount]="false"
        [textProfile]="'My profile'"
        [textSignOut]="'Logout'">
      </ngx-auth-firebaseui-avatar>
    </ng-container>
  </div>
<!--  <div class="navbar-nav me-auto mb-2 mb-lg-0 search-container">-->
<!--    <div class="nav-item nav-link text-uppercase search" routerLinkActive="active">-->
<!--&lt;!&ndash;      <mat-icon matSuffix>search</mat-icon>&ndash;&gt;-->
<!--        -->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="spacer"></div>-->




</div>
