import {Component, Inject, Injector, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthProvider} from 'ngx-auth-firebaseui';
import {MatTabChangeEvent} from "@angular/material/tabs";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";


@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent implements OnInit {

  AuthProvider = AuthProvider;

  data;


  constructor(
    private router: Router,
    private injector: Injector,
  ) { }

  onTabChange(event: MatTabChangeEvent) {
    console.log("on tab change: ", event);
  }

  onSignOut() {
    console.log("Sign-out successful!");
  }

  log(e) {
    console.info(e);
  }

  ngOnInit(): void {
    this.data = this.injector.get(MAT_DIALOG_DATA, null);
  }

  close() {

  }
}

