<h2>System</h2>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="field">
    <mat-form-field appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" placeholder="Enter Name" (blur)="updateName()" (keyup)="updateName()">
      <mat-error *ngIf="form.get('name').hasError('required')">Name is required.</mat-error>
      <mat-error *ngIf="form.get('name').hasError('maxlength')">Name cannot be more than 512 characters long.</mat-error>
    </mat-form-field>
  </div>

  <div class="field">
    <mat-form-field appearance="fill">
      <mat-label>System ID</mat-label>
      <input matInput formControlName="system_id" placeholder="Enter System ID">
      <mat-hint>Cannot be changed after system is created.</mat-hint>
      <mat-error *ngIf="form.get('system_id').hasError('required')">System ID is required.</mat-error>
      <mat-error *ngIf="form.get('system_id').hasError('minlength')">System ID must be at least 3 characters long.</mat-error>
      <mat-error *ngIf="form.get('system_id').hasError('maxlength')">System ID cannot be more than 512 characters long.</mat-error>
      <mat-error *ngIf="form.get('system_id').hasError('pattern')">System ID can only contain lowercase alphanumeric characters and dashes. No whitespace.</mat-error>
    </mat-form-field>
  </div>

  <div class="field">
    <mat-form-field appearance="fill">
      <mat-label>Organization</mat-label>
      <input matInput formControlName="organization" placeholder="Enter Organization">
      <mat-error *ngIf="form.get('organization').hasError('maxlength')">Organization cannot be more than 512 characters long.</mat-error>
    </mat-form-field>
  </div>

  <div class="field">
    <button mat-raised-button color="cancel" class="mr-2" routerLink="/">Cancel</button>
    <button mat-raised-button color="primary" type="submit">Create System</button>
  </div>
</form>
