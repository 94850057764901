import {Directive, ElementRef, OnInit, Renderer2} from "@angular/core";
import {LoginDialogComponent} from "../login-dialog/login-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Directive({
  selector: "[iframeAutoHeight]"
})
export class IframeAutoHeightDirective implements OnInit {
  private el: any;
  private renderer: Renderer2;
  private prevHeight: number;
  private sameCount: number;

  constructor(
    _elementRef: ElementRef,
    _renderer: Renderer2,
    private dialog: MatDialog,
    ) {
    this.el = _elementRef.nativeElement;
    this.renderer = _renderer;
  }

  ngOnInit() {
    const self = this;
    if (this.el.tagName === "IFRAME") {
      this.renderer.listen(window, "message", (event) => {
        if (event && event.data && event.data.action === 'HEIGHT_UPDATE') {
          setTimeout(() => {
            this.renderer.setStyle(
              self.el,
              "height",
              event.data.payload + "px"
            );
          }, 0);
        } else if (event && event.data && event.data.action === 'PERMISSION_DENIED') {
          this.dialog.open(LoginDialogComponent, {
            data: {
              type: 'login',
            }
          });
        }


      });
    }
  }
}
