<!-- Toolbar -->
<app-header></app-header>

<div class="content" role="main">
  <router-outlet></router-outlet>
  <footer>
    <a routerLink="/" routerLinkActive="active">Search</a>&nbsp;-&nbsp;
    <a routerLink="/log" routerLinkActive="active">Log</a>&nbsp;-&nbsp;
    <a routerLink="/log/create" routerLinkActive="active">Create Log</a>&nbsp;-&nbsp;
    <a routerLink="/system/create" routerLinkActive="active">Create System</a>
  </footer>
</div>
