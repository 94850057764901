import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {Angulartics2Module} from 'angulartics2';
import {AngularFireModule} from '@angular/fire/compat';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MarkdownModule} from 'ngx-markdown';
import {FlipComponent, FlipSection} from './flip/flip.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {NgxAuthFirebaseUIModule} from 'projects/ngx-auth-firebaseui/src/public-api';
import {environment} from '../environments/environment';
import {MatTabsModule} from "@angular/material/tabs";
import {HeaderComponent} from "./header/header.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {LoginDialogComponent} from "./login-dialog/login-dialog.component";
import {LogComponent} from "./log/log.component";
import {IframeAutoHeightDirective} from "./shared/iframe-auto-height.directive";
import {LogCreateComponent} from "./log-create/log-create.component";
import {SystemCreateComponent} from "./system-create/system-create.component";
import {FirebaseTokenInterceptor} from "./shared/firebase-token.interceptor";


export const firebaseKey = environment.config;
// export const firebaseKey = {
//   apiKey: 'AIzaSyASG7KxDO2z5AH9r0jlUmwiw68Ap8kG20c',
//   authDomain: 'ngx-auth-firebaseui.firebaseapp.com',
//   databaseURL: 'https://ngx-auth-firebaseui.firebaseio.com',
//   projectId: 'ngx-auth-firebaseui',
//   storageBucket: 'ngx-auth-firebaseui.appspot.com',
//   messagingSenderId: '520699629648'
// };

export function firebaseAppNameFactory() {
  return `Log Swarm`;
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FlipSection,
    FlipComponent,
    LoginDialogComponent,
    LogComponent,
    LogCreateComponent,
    SystemCreateComponent,
    IframeAutoHeightDirective,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    Angulartics2Module.forRoot(),
    AngularFireModule.initializeApp(firebaseKey),
    NgxAuthFirebaseUIModule.forRoot(firebaseKey, firebaseAppNameFactory,
      {
        enableFirestoreSync: true,
        passwordMinLength: 8,
        passwordMaxLength: 16,
        toastMessageOnAuthSuccess: true,
        toastMessageOnAuthError: true,
        authGuardFallbackURL: 'examples/logged-out',
        authGuardLoggedInURL: 'examples/logged-in',
        // enableEmailVerification: false // If you want to disable email verification
      }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MarkdownModule.forRoot({loader: HttpClient}),
    HttpClientModule,
    FormsModule,
    FlexLayoutModule,
    MatCardModule,
    MatButtonModule,
    MatTabsModule,
    MatIconModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: FirebaseTokenInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
