import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LogComponent} from "./log/log.component";
import {LogCreateComponent} from "./log-create/log-create.component";
import {SystemCreateComponent} from "./system-create/system-create.component";


const routes: Routes = [
  { path: 'system/create', component: SystemCreateComponent },
  { path: 'log/create', component: LogCreateComponent },
  { path: 'log', component: LogComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
