import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SystemService} from "../shared/system.service";
import {MatSnackBar} from "@angular/material/snack-bar";


@Component({
  selector: 'app-system-create',
  templateUrl: './system-create.component.html',
  styleUrls: ['./system-create.component.scss'],
})
export class SystemCreateComponent implements OnInit {

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private systemService: SystemService,
    private snackBar: MatSnackBar,
  ) {
    this.createForm();
  }

  ngOnInit() {
  }

  updateName() {
    const value = this.form.get('name').value;

    if (value) {
      const systemIdField = this.form.get('system_id');
      const generatedId = value
        .toLowerCase()
        .trim()
        .replace(/\s/g, '-');
      systemIdField.patchValue(generatedId);
    }
  }



  createForm() {
    this.form = this.fb.group({
      name: ['', [
        Validators.required,
        Validators.maxLength(512),
      ]],
      system_id: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(512),
        Validators.pattern(/^[a-z0-9\-]+$/),
      ]],
      organization: ['', [
        Validators.maxLength(512),
      ]],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const value = this.form.value as any;
      const system = {
        system_id: value.system_id,
        name: this.form.value.name,
        organization: this.form.value.organization
      };

      this.systemService.create(system).subscribe((response) => {
        this.snackBar.open('Created System', undefined, {duration: 3000});
      }, (error) => {
        console.error(error);
        this.snackBar.open(error.message, 'Error', {duration: 3000});
      });

      this.form.reset();
    }
  }
}
