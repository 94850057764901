<div *ngIf="auth.authState| async; then authenticated else none">

</div>

<ng-template #authenticated>
  <mat-card *ngIf="auth.user | async as user">
    <!--<form [formGroup]="updateFormGroup" >-->
    <!--card header-->
    <mat-card-header fxLayout="column" fxLayoutAlign="center center">

      <img *ngIf="authProcess?.getUserPhotoUrl() | async as photoUrl" [src]="photoUrl" mat-card-avatar>

      <div *ngIf="user.emailVerified; then emailVerified else emailNotVerified"></div>
      <ng-template #emailVerified>
        <mat-icon color="primary"
                  [matTooltip]="emailVerifiedText"
                  matTooltipPosition="after">
          verified_user
        </mat-icon>
      </ng-template>
      <ng-template #emailNotVerified>
        <mat-icon color="warn"
                  [matTooltip]="emailNotVerifiedText"
                  matTooltipPosition="after">
          warning
        </mat-icon>
      </ng-template>

    </mat-card-header>

    <!--card content-->
    <mat-card-content *ngIf="editMode; then edit else readonly">
    </mat-card-content>

    <ng-template #edit>
      <form (submit)="save()" [formGroup]="updateFormGroup">

        <mat-card-content fxLayout="column" fxLayoutAlign="center center">
          <div fxLayoutAlign="center">
            <button (click)="changeEditMode()" class="edit-button" color="warn"
                    mat-raised-button>
              {{cancelButtonText}}
            </button>
          </div>

          <!--name-->
          <mat-form-field [appearance]="appearance" class="full-width">
            <mat-label>{{nameText}}</mat-label>
            <input [formControl]="updateNameFormControl"
                   matInput
                   [placeholder]="nameText">
            <mat-icon matSuffix>person</mat-icon>
            <mat-hint align="end" aria-live="polite"> {{ updateNameFormControl.value?.length }}
              / {{ config.nameMaxLength }} </mat-hint>
            <mat-error *ngIf="updateNameFormControl.hasError('required')">
              {{nameErrorRequiredText}}
            </mat-error>
          </mat-form-field>

          <!--email-->
          <mat-form-field [appearance]="appearance" class="full-width">
            <mat-label>{{emailText}}</mat-label>
            <input [formControl]="updateEmailFormControl"
                   matInput
                   [placeholder]="emailText">
            <mat-icon matSuffix>email</mat-icon>
            <mat-error *ngIf="updateEmailFormControl.hasError('required')">
              {{emailErrorRequiredText}} {{updateEmailFormControl.value}}
            </mat-error>
            <mat-error *ngIf="updateEmailFormControl.hasError('pattern')">
              {{emailErrorPatternText}} {{updateEmailFormControl.value}}
            </mat-error>
          </mat-form-field>

          <!--phone number-->
          <mat-form-field *ngIf="false" [appearance]="appearance" class="full-width">
            <mat-label>{{phoneText}}</mat-label>
            <input [formControl]="updatePhoneNumberFormControl"
                   matInput
                   [placeholder]="phoneText"
                   type="tel">
            <mat-icon matSuffix>phone</mat-icon>
            <mat-hint align="end" aria-live="polite">
              {{phoneHintText}}
            </mat-hint>
            <mat-error *ngIf="updatePhoneNumberFormControl.hasError('pattern')">
              {{phoneErrorPatternText}}
            </mat-error>
          </mat-form-field>

        </mat-card-content>

        <mat-card-actions fxLayout="column">
          <button color="primary"
                  mat-button
                  type="submit">
            {{saveChangesButtonText}}
          </button>
        </mat-card-actions>
      </form>
    </ng-template>

    <ng-template #readonly>
      <div fxLayoutAlign="center">
        <button *ngIf="canEditAccount" (click)="changeEditMode()" class="edit-button" color="primary"
                mat-raised-button>
          {{editButtonText}}
        </button>
      </div>

      <!--name-->
      <mat-form-field [appearance]="appearance" class="full-width">
        <mat-label>{{nameText}}</mat-label>
        <input [disabled]="!editMode"
               [value]="user.displayName"
               matInput
               [placeholder]="nameText">
        <mat-icon color="primary" matSuffix>person</mat-icon>
      </mat-form-field>

      <!--email-->
      <mat-form-field [appearance]="appearance" class="full-width">
        <mat-label>{{emailText}}</mat-label>
        <input [disabled]="!editMode"
               [value]="user.email" matInput
               [placeholder]="emailText">
        <mat-icon color="primary" matSuffix>email</mat-icon>
      </mat-form-field>

      <!--phone number-->
      <mat-form-field *ngIf="false" [appearance]="appearance" class="full-width">
        <mat-label>{{phoneText}}</mat-label>
        <input [disabled]="!editMode"
               [value]="user.phoneNumber"
               matInput
               [placeholder]="phoneText">
        <mat-icon color="primary" matSuffix>phone</mat-icon>
      </mat-form-field>

      <mat-card-actions fxLayout="column">
        <button (click)="signOut()" *ngIf="canLogout" color="primary" mat-button>{{signoutButtonText}}</button>
        <button (click)="deleteAccount()" *ngIf="canDeleteAccount" color="warn" mat-button>{{deleteAccountButtonText}}</button>
      </mat-card-actions>

    </ng-template>

  </mat-card>

</ng-template>


<ng-template #none>
  <mat-card class="none-card" fxLayout="row" fxLayoutAlign="center center">
    <mat-card-content fxLayout="row" fxLayoutAlign="center center">
      <mat-icon color="accent">warning</mat-icon>
      <span>{{notLoggedInText}}</span>
    </mat-card-content>
  </mat-card>
</ng-template>
