import {Component, OnInit} from '@angular/core';
import {environment} from "../../environments/environment";


@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss'],
})
export class LogComponent implements OnInit {

  constructor(
  ) {}

  ngOnInit() {
  }
}
